<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewPaisSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-pais-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Agregar Nuevo Pais
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Nombre de Pais -->
          <validation-provider
            #default="validationContext"
            name="Bandera"
            rules="required"
          >
            <b-form-group
              label="Nombre de Pais"
              label-for="nombre-pais"
            >
              <b-form-input
                id="nombre-pais"
                v-model="paisData.nombre"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Bolivia..."
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Bandera Pais -->
          <validation-provider
            #default="validationContext"
            name="Bandera"
            rules="required"
          >
            <b-form-group
              label="bandera de Pais"
              label-for="bandera-pais"
            >
              <b-form-input
                id="nombre-pais"
                v-model="paisData.bandera"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="BO.jpg"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Hoara Pais -->
          <validation-provider
            #default="validationContext"
            name="Hora"
            rules="required"
          >
            <b-form-group
              label="hora de Pais"
              label-for="hora-pais"
            >
              <b-form-input
                id="hora-pais"
                v-model="paisData.hora"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="-4"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Moneda Pais -->
          <validation-provider
            #default="validationContext"
            name="Moneda"
            rules="required"
          >
            <b-form-group
              label="Sigla de Moneda"
              label-for="moneda-pais"
            >
              <b-form-input
                id="moneda-pais"
                v-model="paisData.sigla"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Bs"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Descripcion Moneda Pais -->
          <validation-provider
            #default="validationContext"
            name="MonedaDescripcion"
            rules="required"
          >
            <b-form-group
              label="Descripción de Moneda"
              label-for="des-moneda-pais"
            >
              <b-form-input
                id="des-moneda-pais"
                v-model="paisData.descripcion"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Bolivianos"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Guardar Pais
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
// import store from '@/store'
import { useMutation } from '@vue/apollo-composable'
import ADD_PAIS from '../../../../graphql/cursos/pais/AddPais.gql'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPaisSidebarActive',
    event: 'update:is-add-new-pais-sidebar-active',
  },
  props: {
    isAddNewPaisSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const blankPaisData = {
      nombre: '',
      bandera: '',
      hora: '',
      sigla: '',
      descripcion: '',
    }

    const paisData = ref(JSON.parse(JSON.stringify(blankPaisData)))
    const resetuserData = () => {
      paisData.value = JSON.parse(JSON.stringify(blankPaisData))
    }

    const { mutate: sendPais, onDone, onError } = useMutation(ADD_PAIS, () => ({
      variables: paisData.value,
    }))

    onDone(() => {
      emit('refetch-data')
    })

    onError(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

    const onSubmit = () => {
      sendPais()

      emit('refetch-data')
      emit('update:is-add-new-pais-sidebar-active', false)
      /*
      store.dispatch('app-user/addUser', cursoData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
        */
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      paisData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
